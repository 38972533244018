body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: 'Verdana', cursive, sans-serif;
  color: #ffffff;
}

.wrapper {
  text-align: center;
}

.app {
  background-color: #252d4a;
  width: 750px;
  min-height: 200px;
  height: min-content;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: space-evenly;
  /* align-items: center; */
}

.questionBubbleContainer {
  background-color: #192d73;
  width: 750px;
  min-height: 100px;
  height: min-content;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: space-evenly;
}

.questionBubbleRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.questionBubbleColumn {
  width: 10%;
}

.questionBubble {
  height: min-content;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: space-evenly;
}

.header {
  color: #252d4a !important;
}

.score-section {
  display: flex;
  font-size: 24px;
  align-items: center;
}

/* QUESTION/TIMER/LEFT SECTION */
.question-section {
  width: 100%;
  position: relative;
}

.question-count {
  margin-bottom: 20px;
}

.question-count span {
  font-size: 28px;
}

.question-text {
  margin-bottom: 12px;
}

.timer {
  margin-left: 2.5%;
  margin-top: 13%;
  position: static;
  top: 100px;
}

.examPageWrapper h1 {
  margin-top: 1%;
  margin-bottom: 1%;
}

.timer-text {
  background: rgb(230, 153, 12);
  padding: 15px;
  margin-top: 20px;
  margin-right: 20px;
  border: 5px solid rgb(255, 189, 67);
  border-radius: 15px;
  text-align: center;
}

/* ANSWERS/RIGHT SECTION */
.answerSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.answerOption {
  width: 100%;
}

button {
  font-size: 16px;
  color: #ffffff;
  background-color: #252d4a;
  border-radius: 15px;
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border: 3px solid #ffffff;
}

button:not([disabled]) {
  cursor: pointer;
}

.submitContainer {
  padding: 10px;
}

.btnSubmitPending {
  font-size: 32px;
  margin: auto;
  width: 100%;
}

.correct {
  background-color: #2f922f;
}

.incorrect {
  background-color: #ff3333;
}

.answered {
  color: #252d4a;
}

.unanswered {
  color: #ff3333;
}

button:hover:not([disabled]) {
  background-color: #555e7d;
}

button:focus {
  outline: none;
}

button svg {
  margin-right: 5px;
}

button:disabled {
  color: #666;
}

.logo {
  padding-bottom: 20px;
  justify-content: flex-start;
}

.questionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.question-selection-items {
  margin-top: 30px;
  display: inline-flex;
}

.navigationButtonsContainer {
  display: flex;
  justify-content: space-between;
}

.btnBack,
.btnNext {
  width: 30%;
}

.answered a {
  padding-right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: #252d4a;
}

.unanswered a {
  padding-right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: #252d4a;
}

.unanswered a {
  color: #ff3333;
}

.pdf {
  padding-top: 200px;
  color: black;
}

.DisplayBlock {
  width: 100% !important;
  display: block !important;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

.react-pdf__Page__textContent {
  display: none;
}

.btnExitRight{
  font-size: 32px;
  margin: auto; 
  width: 10%;
  position:absolute;
  right:75px;
  top: 250px;
}

.btnSubmitReady {
  font-size: 32px;
  margin: auto;
  /*width: 75%;*/
  width: 15%;
  position:absolute;
  left:75px;
  top: 250px;
}
